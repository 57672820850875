<aside class="side-menu">
  <a class="main" [routerLink]="['/dashboard']">
    <fa-icon [icon]="['fas', 'tachometer-alt']"></fa-icon>
    Dashboard
  </a>
  <h2 class="sub-header">
    <fa-icon [icon]="['fas', 'users']"></fa-icon>
    Users
  </h2>
  <ul>
    <li>
      <a [routerLink]="['/users']" title="Users list">Users list</a>
    </li>
    <li>
      <a [routerLink]="['/users-payouts']" title="Users list">List of payouts</a>
    </li>
  </ul>
  <h2 class="sub-header">
    <fa-icon [icon]="['fas', 'list']"></fa-icon>
    Streams
  </h2>
  <ul>
    <li>
      <a [routerLink]="['/streams']" title="Streams list">Streams list</a>
    </li>
    <li>
      <a [routerLink]="['/featured-streams']" title="Featured Streams">Featured Streams</a>
    </li>
  </ul>
  <h2 class="sub-header">
    <fa-icon [icon]="['far', 'sticky-note']"></fa-icon>
    Notes
  </h2>
  <ul>
    <li>
      <a [routerLink]="['/notes']" title="Notes list">Notes list</a>
    </li>
    <li>
      <a [routerLink]="['/featured-notes']" title="Featured Notes">Featured Notes</a>
    </li>
    <li>
      <a [routerLink]="['/notes-flagged']" title="Flagged notes">Flagged Notes</a>
    </li>
    <!-- <li>
      <a [routerLink]="['/trashed-notes']" title="Trashed notes">Trashed Notes</a>
    </li> -->
  </ul>
  <h2 class="sub-header">
    <fa-icon [icon]="['fas', 'comments']"></fa-icon>
    Comments
  </h2>
  <ul>
    <li>
      <a [routerLink]="['/flagged-comments']" title="Flagged comments">Flagged comments</a>
    </li>
  </ul>
</aside>
