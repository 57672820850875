import {HttpClient, HttpParams} from '@angular/common/http';

import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Inject, Injectable} from '@angular/core';

import {User} from '../../app/shared/types/user';
import { Note } from '../../app/shared/types/note';
import { Stream } from '../../app/shared/types/stream';

@Injectable()
export class AdminService {

  search: any = {};

  constructor(
    @Inject(DOCUMENT) private document,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  public getAdminProfile(): Promise<User> {
    const httpOptions = {
      withCredentials: true
    };

    return this.http.get('/api/user/profile', httpOptions)
      .toPromise()
      .then((user: User) => {
        return user;
      });
  }

  public getUsers(searchParams: {
    query?: string;
    sort?: string;
    size?: number;
    fromOffset?: number;}): Promise<any> {  // eslint-disable @typescript-eslint/no-explicit-any
    console.log('getUsers: ', searchParams);
    const params = new HttpParams()
      .set('q', (searchParams.query) ? searchParams.query : '')
      .set('sort', (searchParams.sort) ? searchParams.sort : '')
      .set('size', (searchParams.size) ? searchParams.size.toString() : '')
      .set('fromOffset', (searchParams.fromOffset) ? searchParams.fromOffset.toString() : '0');

    return this.http.get('/api/admin/user/simple/query', {params})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getNotes(queryObject: {
    q?: string;
    tags?: string;
    size?: number;
    fromOffset?: number;
    fromDate?: string;
    ratingMin?: any;
  }): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set( 'q', (queryObject.q) ? queryObject.q : '' )
      .set( 'tag', (queryObject.tags) ? queryObject.tags : '' )
      .set( 'fromDate', (queryObject.fromDate) ? queryObject.fromDate : '' )
      .set( 'ratingMin', (queryObject.ratingMin) ? queryObject.ratingMin : '' )
      .set( 'getDetails', '1' )
      .set( 'size', (queryObject.size) ? queryObject.size.toString() : '')
      .set( 'fromOffset', (queryObject.fromOffset) ? queryObject.fromOffset.toString() : '0')
      .set( 'state', 'PUBLIC,HIDDEN');

    return this.http.get<any>(`/api/search/posts/simple/query` , { params })
      .toPromise();
  }

  public getStreams(queryObject: {
    q?: string;
    tags?: string;
    size?: number;
    fromOffset?: number;
    fromDate?: string;
    ratingMin?: any;
  }): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set( 'q', (queryObject.q) ? queryObject.q : '' )
      .set( 'tags', (queryObject.tags) ? queryObject.tags : '' )
      .set( 'fromDate', (queryObject.fromDate) ? queryObject.fromDate : '' )
      .set( 'ratingMin', (queryObject.ratingMin) ? queryObject.ratingMin : '' )
      .set( 'getDetails', '1' )
      .set( 'size', (queryObject.size) ? queryObject.size.toString() : '')
      .set( 'fromOffset', (queryObject.fromOffset) ? queryObject.fromOffset.toString() : '0');

    return this.http.get<any>(`/api/search/channels/simple/query` , { params })
      .toPromise();
  }

  public getFlaggedNotes(state: string): Promise<any> {
    return this.http.get(`/api/admin/flagged/posts/${state}`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getFlaggedComments(state: string): Promise<any> {
    return this.http.get(`/api/admin/flagged/post-comments/${state}`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public setCommentTakeDownFlag(status: string, flagId: string) {
    const url = `/api/admin/flagged/post-comment/${status}/takedown/${flagId}`;
    return this.http.post(url, {}).toPromise();
  }

  public getNoteInfo(postId: string): Promise<any> {
    return this.http.get(`/api/post/${postId}/info`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getNoteFlags(postId: string): Promise<any> {
    return this.http.get(`/api/admin/post/${postId}/flags`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getUserNotes(userId: string): Promise<any> {
    return this.http.get(`/api/admin/user/${userId}/posts`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getPayoutStatus(status: any): Promise<any> {
    return this.http.get(`/api/admin/payout/list/status/${status}`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getUserPayouts(userId: string): Promise<any> {
    return this.http.get(`/api/user/${userId}/payouts/list`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public getStreamPaymentsSummary(streamId: string, period: string, perNumber: number): Promise<any> {
    return this.http.get(`/api/channel/${streamId}/payments/summary/${period}/${perNumber}`, {})
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  public updatePayout(payoutId: string, staffNote: string, status: string) {
    const url = `/api/admin/payout/${payoutId}/update`;

    const params = new HttpParams()
      .set('staffNotes', staffNote)
      .set('status', status);

    return this.http.post(url, params).toPromise();
  }

  public setNoteTakeDownFlag(status: string, flagId: string) {
    const url = `/api/admin/flagged/post/${status}/takedown/${flagId}`;
    return this.http.post(url, {}).toPromise();
  }

  public setStreamOrder(streamId: string, order: number) {
    const url = `/api/admin/channel/${streamId}/featured/${order}`;
    return this.http.put(url, {}).toPromise();
  }

  public getUserTaxDocPath(userId: string): string {
    return `/api/user/${userId}/profile/legal/tax_document`;
  }

  public getUserIdentityDocPath(userId: string): string {
    return `/api/user/${userId}/profile/legal/identity_document`;
  }

  public getUserTransactions(userId: string, type: string, fromDate: number | string, toDate: number | string) {
    const params: HttpParams = new HttpParams()
      .set( 'fromDate', (fromDate) ? fromDate.toString() : '' )
      .set( 'toDate', (toDate) ? toDate.toString() : '' );

    return this.http.get<any>(`/api/user/${userId}/${type}` , { params })
      .toPromise();
  }

  public setUserName(userId: string, user: User) {
    return this.http.post(`api/user/${userId}/profile`, user, {})
      .toPromise();
  }

  public deleteUser(userId: string) {
    return this.http.delete(`/api/user/${userId}/profile/delete`, {})
      .toPromise();
  }

  public getStreamSales(channelId: string, queryObject: {
    fromDate?: any;
    toDate?: any;
    limit?: any;
    fromOffset?: any; }): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set( 'fromDate', (queryObject.fromDate) ? queryObject.fromDate : '' )
      .set( 'toDate', (queryObject.toDate) ? queryObject.toDate : '' )
      .set( 'limit', (queryObject.limit) ? queryObject.limit : '' )
      .set( 'fromOffset', (queryObject.fromOffset) ? queryObject.fromOffset : '0' );

    return this.http.get<any>(`/api/channel/${channelId}/sales` , { params })
      .toPromise();
  }

  public deleteStream(streamId: string): Promise<any> {
    return this.http.delete(`/api/channel/${streamId}/delete`, {})
      .toPromise();
  }

  public banUser(userId: string) {
    return this.http.put(`/api/user/${userId}/profile/banned`, {})
      .toPromise();
  }

  public unbanUser(userId: string) {
    return this.http.delete(`/api/user/${userId}/profile/banned`, {})
      .toPromise();
  }

  public getFeaturedNotes(): Promise<Note[] | null | object> {
    return this.http.get(`api/search/posts/featured`, {})
      .toPromise();
  }

  public getFeaturedStreams(): Promise<Stream[] | null | object> {
    return this.http.get(`api/search/channels/featured`, {})
      .toPromise();
  }

  public changeFeaturedNotePriority(noteId: string | number, featuredOrder: number): Promise<Note[] | null | object> {
    return this.http.put(`/api/admin/post/${noteId}/featured/${featuredOrder}`, {})
      .toPromise();
  }

  public changeFeaturedStreamPriority(streamId: string | number, featuredOrder: number): Promise<Stream[] | null | object> {
    return this.http.put(`/api/admin/channel/${streamId}/featured/${featuredOrder}`, {})
      .toPromise();
  }

  public getUserBankingInfo(userId: string | number): Promise<unknown> {
    return this.http.get(`/api/user/${userId}/profile/bank/list`, {})
      .toPromise();
  }

  public deleteNote(noteId: number, permanentDelete?: boolean): Promise<unknown> {
    const params: HttpParams = new HttpParams()
    if (permanentDelete) {
      console.log(permanentDelete);
      params.set('forceDelete', '1');
    }

    return this.http.delete(`/api/post/${noteId}`, {params})
      .toPromise();
  }

  public restoreNote(noteId: number, state: string): Promise<any> {
    return this.http.post(`/api/post/${noteId}/state/${state}`, {}).toPromise();
  }

  public getTrashedNotes(userId: number | string): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set('state', 'TRASH')

    return this.http.get(`/api/user/${userId}/posts/list`, {params})
      .toPromise();
  }

  public acceptFlag(flagId: number): Promise<any> {
    return this.http.post(`/api/admin/flagged/post/accept/takedown/${flagId}`, {}).toPromise();
  }

  public rejectFlag(flagId: number): Promise<any> {
    return this.http.post(`/api/admin/flagged/post/reject/takedown/${flagId}`, {}).toPromise();
  }
}
